import {ButtonHTMLAttributes} from 'react';

export default function PrimaryButton({
    className = '',
    disabled,
    children,
    ...props
}: ButtonHTMLAttributes<HTMLButtonElement>) {
    return (
        <button
            {...props}
            className={
                `text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 me-2 duration-150 ${
                    disabled ? 'opacity-25 ' : ''
                }` + (className || '')
            }
            disabled={disabled}
        >
            {children}
        </button>
    );
}
